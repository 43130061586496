
import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
  };
    const fetch_users = () => axios.get('https://laranew.intbinary.com/api/users')
      .then(response => {
        setUsers(response.data);
        
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
 

  useEffect(() => { 
    fetch_users();
  }, []);
  const get_Name = (e) => {
    alert('You Clicked : ' + e);

  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://laranew.intbinary.com/api/saveUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      console.log('Response:', data);

      if (response.ok) {
        fetch_users();
        
      } else {

        alert('Error submitting form');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <div>
      <h1>User List</h1>
      <ul>
        {users.map((user) => (
          <li key={user.id} onClick={() => get_Name(user.name)}>{user.name}</li>
        ))}
      </ul>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>

  );
};

export default App;
